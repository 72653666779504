import React from 'react';
import DaemonChoice from './Nav';

// Assets

const Skills = () => {
  return (
    <>
      <DaemonChoice/>
    </>
  )
}

export default Skills